import React, { useEffect, useState } from "react";
import axios from "axios";
import "./styles.css";
import Sample from "./example";

export const fonts = [
  { codigo: 'playballRegular', value: 'Playball-Regular' },
  { codigo: 'whisperRegular', value: 'Whisper-Regular' },
  { codigo: 'alexBrushRegular', value: 'AlexBrush-Regular' },
  { codigo: 'parisienneRegular', value: 'Parisienne-Regular' },
  { codigo: 'zocial', value: 'Zocial' },
  { codigo: 'zeyadaRegular', value: 'Zeyada-Regular' },
  { codigo: 'tangerineRegular', value: 'Tangerine-Regular' },
  { codigo: 'satisfyRegular', value: 'Satisfy-Regular' },
  { codigo: 'kolkerBrushRegular', value: 'KolkerBrush-Regular' },
  { codigo: 'jainiPurvaRegular', value: 'JainiPurva-Regular' },
  { codigo: 'dancingScriptRegular', value: 'DancingScript-Regular' },
  { codigo: 'caveatRegular', value: 'Caveat-Regular' },
  { codigo: 'briemHandRegular', value: 'BriemHand-Regular' },
  { codigo: 'HerrVonMuellerhoffRegular', value: 'HerrVonMuellerhoff-Regular' },
  { codigo: 'meieScriptRegular', value: 'MeieScript-Regular' },
  { codigo: 'stalemateRegular', value: 'Stalemate-Regular' },
  { codigo: 'pinyonScriptRegular', value: 'PinyonScript-Regular' },
  { codigo: 'greatVibesRegular', value: 'GreatVibes-Regular' },
  { codigo: 'mrsSaintDelafieldRegular', value: 'MrsSaintDelafield-Regular' },
  { codigo: 'marckScriptRegular', value: 'MarckScript-Regular' },
  { codigo: 'arizoniaRegular', value: 'Arizonia-Regular' },
  { codigo: 'camelliaSignatureRegular', value: 'CamelliaSignature-Regular' },
  { codigo: 'amalfiCoastRegular', value: 'Amalfi Coast' },
  { codigo: 'thesignature', value: 'Thesignature' },
  { codigo: 'farmhouse', value: 'Farmhouse' },
  { codigo: 'kingshare', value: 'Kingshare' },
];

export const BASE_URI = 'https://gestao.proversaude.com.br:64044'; // 'http://localhost:5052';
export const headers = {
  "content-type": "application/json",
  "x-access-token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjI1Mzg4MjZjLWY2YzItNGMyNC1hYjkzLWZjZGUxYWNkYWJmMCIsIm5vbWUiOiJTdXBvcnRlIEFwcFdCIDIiLCJlbWFpbCI6InN1cG9ydGVAYXBwd2IuY29tLmJyIiwiaWF0IjoxNzMxOTMzMzI3LCJleHAiOjE3MzcxMTczMjcsImF1ZCI6InVybjpqd3Q6dHlwZTpyZWZyZXNoIiwiaXNzIjoidXJuOnN5c3RlbTp0b2tlbi1pc3N1ZXI6dHlwZTpyZWZyZXNoIn0.jiB5SgrnQnWsaQldP1oVFBYitDo_xygyryhbfuY7U7E"
};

const loadData = async (parts) => {
 
  if (!parts || parts.length < 4) {
    return null;
  }

  let codigo = '';
  if (parts[1] === 'liberty') {
    codigo = parts[3];
  } else if (parts[1] === 'vendedor') {
    codigo = parts[4];
  } else {
    codigo = parts[2];
  }

  try {
    const graphqlQuery = {
      "query": `query($codigo: ID!) {
          beneficiario(codigo: $codigo) {
            codigo
            empresa
            nomeFantasia
            cpf
            dataNascimento
            email
            ddd
            celular
            telemedicina
            _modalidade {
              nome
            }
            vendedor
            _vendedor {
              nomeFantasia
              email
              ddd
              celular
            }
            contratoStatus
            contratoOriginal
            contratoAssinado
            telemedicinaOriginal
            naoFuneralOriginal
          }
        }`,
      "variables": {
        "codigo": codigo,
      }
    };

    const response = await axios({
      url: `${BASE_URI}/graphql`,
      method: 'post',
      headers: headers,
      data: graphqlQuery
    });
    console.log(response);
    const { data: { data: { beneficiario } } } = response;
    return beneficiario;

  } catch (error) {
    console.log(error);
    return null;
  }
}

export default function App() {
  const [parts, setParts] = useState([]);
  const [beneficiario, setBeneficiario] = useState(null);

  useEffect(() => {
    const { pathname } = window.location;
    setParts(`${pathname}`.split('/').filter((e) => `${e}`.length > 0));
  }, []);

  useEffect(() => {
    (async () => {
      const response = await loadData(parts);
      setBeneficiario(response);
    })();
  }, [parts]);

  if (!beneficiario) {
    return (
      <div className="App" style={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>Carregando....</div>
    )
  }

  let isLiberty = (parts[1] === 'liberty') && (!beneficiario.contratoAssinado || (`${beneficiario.contratoAssinado}`.length === 0));
  let isBeneficiario = !isLiberty && ((parts[1] !== 'vendedor') && (beneficiario.contratoStatus !== 2));
  let isVendedor = !isLiberty && ((parts[1] === 'vendedor') && (beneficiario.contratoStatus === 5));

  if (isBeneficiario || isVendedor) {
    return (
      <div className="App" style={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <span><b>Seu Contrato já se encontra Assinado!</b></span>
          <span>Aguarde a cópia do seu contrato por e-mail, assim que o todos os participantes tiverem finalizado a assinatura.</span>
        </div>
      </div>
    )
  }

  return (
    <div className="App">
      <Sample beneficiario={beneficiario} parts={parts} />
    </div>
  );
}
